@use '../../assets/styles/variables.scss';

@mixin color($theme) {
    .isLightTheme {
        .mat-mdc-tab.mdc-tab {
            opacity: 1;
        }

        .mat-mdc-list-item.selected {
            background-color: #e5e5e5;
        }
    }

    .isDarkTheme {
        .mat-mdc-tab-disabled {
            color: white;
            opacity: 1;
        }

        .mat-mdc-list-item.selected {
            background-color: #404040;
        }
    }
}

::ng-deep .mat-mdc-tab-body-wrapper {
    height: 100%;
}

// Push the main toolbar buttons to the right side.
::ng-deep .mdc-tab:nth-child(3) .mdc-tab__content {
    margin-left: auto;
}

::ng-deep .hover_table_row:hover {
    background-color: #cfd8dc;
}

::ng-deep .mat-mdc-tab-disabled {
    flex: 1 1 auto !important;
    justify-content: end;
}

::ng-deep .editor-details .mat-mdc-tab-disabled {
    flex: 1 1 auto !important;
    justify-content: end;
}

::ng-deep .as-split-gutter-icon {
    background-image: none !important;
}

::ng-deep .message-editor .mat-expansion-panel-body {
    padding: 0 0px 16px !important;
}

::ng-deep .mat-mdc-tab-body-content {
    overflow: initial !important;
}

::ng-deep .mat-mdc-dialog-content {
    min-width: 0 !important;
}

/* SWIPE component: should be moved there */
::ng-deep .ngstd-main-canvas mat-expansion-panel {
    padding: 0 !important;
    width: 100%;
}

::ng-deep .ngstd-main-canvas mat-expansion-panel-header {
    padding-left: 0 !important;
}

::ng-deep .ngstd-main-canvas .ngstd-item-wrapper {
    background-color: red;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 2px;
}

::ng-deep .ngstd-main-canvas .ngstd-delete-indicator {
    height: 54px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    align-items: center;
    flex-shrink: 0;
}
::ng-deep .ngstd-main-canvas .ngstd-delete-indicator .ngstd-delete-icon {
    margin-left: 16px;
    margin-right: 16px;
    width: 24px;
    height: 24px;
    font-size: 24px;
    color: #fff;
}

::ng-deep .ngstd-main-canvas .ngstd-item-container {
    padding-left: 24px !important;
    background-color: #fff;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    height: inherit;
}

::ng-deep .ngstd-main-row .ngstd-item-wrapper {
    background-color: red;
    width: 100%;
    height: 100%;
    margin-bottom: 1px;
}

::ng-deep .ngstd-main-row td {
    height: 48px;
}

::ng-deep .ngstd-main-row .ngstd-delete-indicator {
    height: 47px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    align-items: center;
    flex-shrink: 0;
}
::ng-deep .ngstd-main-row .ngstd-delete-indicator .ngstd-delete-icon {
    margin-left: 16px;
    margin-right: 16px;
    width: 24px;
    height: 24px;
    font-size: 24px;
    color: #fff;
}

::ng-deep .ngstd-main-row .ngstd-item-container {
    padding-left: 24px !important;
    background-color: #fff;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    height: inherit;
}

::ng-deep .ngstd-main-row td.mat-mdc-cell:first-of-type {
    padding-left: 0px !important;
}
/* SWIPE component: should be moved there */
