@use 'sass:map';
@use '@angular/material' as mat;
@use 'angular-material-css-vars' as mat-css;
@use 'angular-material-css-vars/public-util' as mat-css-utilities;
@use '../src/assets/styles/variables.scss';
@use 'app/can-database-editor/can-database-editor.component.scss' as
    canDatabaseEditor;

// Include tailwindcss
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '../node_modules/@angular/material/prebuilt-themes/indigo-pink.css';
@import 'material-icons/iconfont/material-icons.css';

@import './assets/styles/material';

/* Dialog */
.mat-dialog-title {
    font-weight: 400 !important; // Don't have bold titles.
}

.mat-mdc-dialog-content {
    // Automatically size the dialog content
    overflow: visible !important;
    // The default bottom padding is way too big.
    padding-bottom: 12px !important;
}

.mat-mdc-dialog-surface {
    border-radius: 12px !important; // Bigger rounded corners.
}

.cdk-overlay-pane:has(.mat-mdc-dialog-container) {
    // leave a gap around dialogs on small screens.
    padding: 12px;
}

// A centered row for holding buttons.
.btn-bar {
    display: flex;
    justify-content: center;
    height: 36px;
}

// Correctly align icon inside a button.
.btn-icon {
    margin-right: 8px !important;
}

.mat-drawer-container {
    background-color: transparent !important;
}

mat-list-option {
    border-radius: 8px;
    overflow: hidden;
    // Make the text un-selectable
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.h-content {
    height: calc(100% - (variables.$navBarHeight + variables.$toolBarHeight));
}

.h-content-no-tool-bar {
    height: calc(100% - variables.$navBarHeight);
}

.scroll-content {
    height: calc(100% - (variables.$navBarHeight + variables.$toolBarHeight));
    overflow: auto;
}

.scroll-content-no-tool-bar {
    height: calc(100% - variables.$navBarHeight);
    overflow: auto;
}

.label {
    color: rgba(0, 0, 0, 0.54);
    font-family: Roboto;
}

/* scrollbars */
.isLightTheme {
    --scrollbar-foreground: hsl(var(--b3));
}
.isDarkTheme {
    // b3 is too dark in dark mode, so use this custom value
    --scrollbar-foreground: #424242;
}
* {
    --scrollbar-background: transparent;
    scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
}
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: var(--scrollbar-foreground);
}
::-webkit-scrollbar-track {
    background: var(--scrollbar-background);
}

.spacer {
    flex: 1 1 auto;
}

// Make error tooltips have red background.
mat-tooltip-component .mat-mdc-tooltip.error-tooltip > div {
    background-color: hsl(0, 80%, 40%);
    font-size: 12px;
    border-radius: 8px;
}

// Setup themes

// Use the Montserrat font for different headings.
// I tinkered with the font weights a little.
// headline-4 has a smaller font size.
$custom-typography: mat.m2-define-typography-config(
    $headline-4: mat.m2-define-typography-level(30px, 36px, 400, 'Montserrat'),
    $headline-5: mat.m2-define-typography-level(24px, 32px, 500, 'Montserrat'),
    $headline-6: mat.m2-define-typography-level(20px, 32px, 500, 'Montserrat'),
    $subtitle-1: mat.m2-define-typography-level(16px, 28px, 500, 'Montserrat'),
    $subtitle-2: mat.m2-define-typography-level(14px, 22px, 600, 'Montserrat'),
    $button: mat.m2-define-typography-level(14px, 36px, 400, 'Montserrat', 0.1em),
);

$app-theme: null;
@include mat-css.init-material-css-vars($typography-config: $custom-typography)
    using ($mat-css-theme) {
    $app-theme: $mat-css-theme !global;
    $config: mat.m2-get-color-config($app-theme);
    $primary: map.get($config, primary);

    @layer utilities {
        $colors: (
            50: var(--palette-primary-contrast-50-rgb),
            100: var(--palette-primary-contrast-100-rgb),
            200: var(--palette-primary-contrast-200-rgb),
            300: var(--palette-primary-contrast-300-rgb),
            400: var(--palette-primary-contrast-400-rgb),
            500: var(--palette-primary-contrast-500-rgb),
            600: var(--palette-primary-contrast-600-rgb),
            700: var(--palette-primary-contrast-700-rgb),
            800: var(--palette-primary-contrast-800-rgb),
            900: var(--palette-primary-contrast-900-rgb),
        );
        @each $number, $color in $colors {
            .text-on-primary-#{$number} {
                color: $color;
            }

            .border-on-primary-#{$number} {
                color: $color;
            }
        }
    }

    button.mdc-button.mat-primary:not(.mat-mdc-outlined-button) {
        color: var(--palette-primary-contrast-500-rgb);
    }

    button.mdc-button.mat-warn {
        color: #ffffff;
    }

    .isLightTheme {
        .selected-row,
        .mat-mdc-row:hover {
            background: #f5f5f5 !important;
        }

        .as-split-gutter {
            background-color: #e5e5e5 !important;
        }
    }

    .isDarkTheme {
        .selected-row,
        .mat-mdc-row:hover {
            background: #383838 !important;
        }

        .as-split-gutter {
            background-color: #404040 !important;
        }
    }

    @include canDatabaseEditor.color($mat-css-theme);
}

* {
    // Remove highlight color on mobile.
    -webkit-tap-highlight-color: transparent;
    // Animate background color transitions (toggling dark mode).
    transition: background-color 100ms ease;
    // Set the default radius for cards.
    --mdc-outlined-card-container-shape: 8px !important;
    // Set the default radius for buttons
    --mdc-filled-button-container-shape: 8px !important;
    --mdc-outlined-button-container-shape: 8px !important;
    --mdc-text-button-container-shape: 8px !important;
    // Set the default font weight for buttons.
    --mdc-typography-button-font-weight: 400;
    // Set the background color for dialogs.
    --mdc-dialog-container-color: hsl(var(--b1));
}

.isLightTheme * {
    // Set the text color in dialog.
    --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.87);
}

.isDarkTheme * {
    // Set the text color in dialog.
    --mdc-dialog-supporting-text-color: white;
}

// Fix form field border clash with material
// From https://stackoverflow.com/a/75103861
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
    .mdc-notched-outline__notch {
    border-right-style: hidden;
}

/* Loading Spinner */
.loading-shade {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 56px;
    right: 0;
    z-index: 101;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Loading Spinner Wrapper */
.spinner-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

/* Dragging styles */
.cdk-drag-preview {
    box-shadow:
        0 4px 4px -4px rgba(0, 0, 0, 0.2),
        0 8px 12px 0px rgba(0, 0, 0, 0.1),
        0 4px 16px 4px rgba(0, 0, 0, 0.1);
}
.cdk-drag-animating {
    transition: transform 100ms cubic-bezier(0, 0, 0.2, 1);
}

/* Overwrite the mdc button icon styles. */
.mdc-button > .mat-icon {
    height: 24px !important;
    width: 24px !important;
}

// Override some problems with the progress bar background coloring.
.isLightTheme .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
    background-color: #e5e5e5;
}
.isDarkTheme .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
    background-color: #171717;
}

mat-select[aria-expanded="true"] {
    // Remove bottom rounded corners from select when overlay is open.
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    // Keep border of the select highlighted, even if overlay has focus.
    border: 1px solid hsl(var(--p));
}

.custom-select-overlay {
    // Fixes the overlay position.
    transform: translateX(-7px) translateY(8px) !important;
    .mat-mdc-select-panel {
        border-radius: 0.375rem;
        padding: 0;
        background-color: hsl(var(--b1));
        color: hsl(var(--bc));
        border: 1px solid hsl(var(--p));
    }
}

.mat-mdc-optgroup-label {
    font-weight: bold;
}
