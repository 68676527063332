
@import "./variables";

.cdk-global-overlay-wrapper {
  overflow: auto;
  height: 100vh;
}

.cdk-overlay-container {
  .mat-mdc-dialog-container {
    overflow: visible;
  }
}

mat-tooltip-component {
  .remove-tooltip {
    margin-bottom: 0;
  }
}
